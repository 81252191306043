import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import sjcl from 'sjcl'

Vue.use(Vuex)

let BASE_URL = 'https://s-api.tapeacall.com'
if(window.location.hostname === 'app.tapeacall.com') {
  BASE_URL = 'https://api.tapeacall.com'
}

const SKU_API_URL        = '/v3/sku/'
const SUBSCRIPTION_URL   = '/v3/subscription/bt/'
const BT_INVOICES        = '/v3/subscription/bt/invoices/'
const CONSENT            = '/v3/consent'
const SIGNUP_API_URL     = '/v3/login/firebase'
const GET_UUID           = '/v3/ad-id/google'
const RECEIPT_API_URL    = '/v3/receipt'
const PRIVACY_API_URL    = '/v3/privacy-policy'
const TOS_API_URL        = '/v3/terms-of-service'
const IP_URL             = 'https://ip2c.org/'
const VUEX               = 'vuex'
const LAST_LOGIN         = 'last_visited'

//Dashboard
const FETCH_ACCOUNT      = '/v3/account'
const FETCH_RECORDINGS   = '/v3/recording/'
const FETCH_CATEGORIES   = '/v3/category/'
const ADD_NEW_CATEGORY   = '/v3/category'
const ADJUST_RECORDING   = '/v3/call/'
const UPLOADED_RECORDING = '/v3/call/upload'
const EDIT_TRANSCRIPTION = '/v3/transcription/'

const config = {
  headers: {
    'App_identifier': 'tac.web.bt'
  }
}

export default new Vuex.Store({
  state: {
    userPhoneNumber: null,
    userAccount: null,
    userToken: null,
    firebaseUID: null,
    countryCode: null,
    skuus: null,
    activeSubscriptions: [],
    receipts: null,
    ppHash: '',
    termsHash: '',
    UUID: null,
    searchTerm: '',
    recordings: [],
    invoices: [],
    lists: [],
    activeTab: 'recordings',
    mobileShowFilterLists: false,
    showQRCodes: false,
    startDate: null,
    endDate: null,
    highlightQR: false,
    onlyCountries: ['AR', 'AU', 'AT', 'BH', 'BE', 'BR', 'BG', 'CA', 'CL', 'CN', 'CO', 'CR', 'HR', 'CY', 'CZ', 'DE', 'DK', 'DO', 'EC', 'EE', 'FI', 'FR', 'GR', 'HK', 'IE', 'IL', 'IR', 'IS', 'IT', 'IQ', 'JP', 'LV', 'LT', 'LU', 'MY', 'MT', 'MX', 'NL', 'NZ', 'NO', 'PA', 'PE', 'PL', 'PT', 'RO', 'RU', 'SG', 'SI', 'SK', 'SV', 'ZA', 'ES', 'SE', 'CH', 'TH', 'TR', 'UA', 'VE', 'GB', 'US']
  },
  plugins: [createPersistedState({paths: ['userPhoneNumber', 'userAccount', 'userToken', 'firebaseUID', 'countryCode', 'skuus', 'receipts', 'ppHash',
    'termsHash', 'UUID', 'searchTerm', 'activeSubscriptions', 'invoices', 'lists', 'activeTab', 'mobileShowFilterLists', 'showQRCodes', 'startDate',
     'endDate', 'highlightQR', 'onlyCountries'
  ]})],
  getters: {
    getHighlightQR: (state) => {
      return state.highlightQR
    },
    getFirebaseUID: (state) => {
      return state.firebaseUID
    },
    getUserPhoneNumber: (state) => {
      return state.userPhoneNumber
    },
    getUserAccount: (state) => {
      return state.userAccount
    },
    getUserToken: (state) => {
      return state.userToken
    },
    getSKUUSArray: (state) => {
      return state.skuus
    },
    getReceipts: (state) => {
      return state.receipts
    },
    getPPHash: (state) => {
      return state.ppHash
    },
    getTermsHash: (state) => {
      return state.termsHash
    },
    getUUID: (state) => {
      return state.UUID
    },
    getCountryCode: (state) => {
      if(state.onlyCountries.includes(state.countryCode)) {
        return state.countryCode
      } else {
        return 'US'
      }
    },
    getOnlyCountries: (state) => {
      return state.onlyCountries
    },
    getActiveSubscriptions: (state) => {
      return state.activeSubscriptions.sort((a, b) => {
        return a.status.localeCompare(b.status)
      })
    },
    getSearchTerm: (state) => {
      return state.searchTerm
    },
    getFilteredRecordings: (state) => {
      if(state.startDate && state.endDate) {
        return state.recordings.filter((rec) => new Date(rec.created).getTime() >= new Date(state.startDate).getTime() && new Date(rec.created).getTime() <= new Date(state.endDate).getTime())
      } else {
        return state.recordings
      }
    },
    getRecordings: (state) => {
      return state.recordings
    },
    getInvoices: (state) => {
      return state.invoices
    },
    getLists: (state) => {
      return state.lists
    },
    getActiveTab: (state) => {
      return state.activeTab
    },
    getMobileShowFilterLists: (state) => {
      return state.mobileShowFilterLists
    },
    getShowQRCodes: (state) => {
      return state.showQRCodes
    }
  },
  mutations: {
    setHighlightQR: (state, val) => {
      state.highlightQR = val
    },
    setStartDate: (state, val) => {
      if(val) {
        state.startDate = new Date(val)
      } else {
        state.startDate = null
      }
    },
    setEndDate: (state, val) => {
      if(val) {
        state.endDate = new Date(val)
      } else {
        state.endDate = null
      }
    },
    setFirebaseUID: (state, val) => {
      state.firebaseUID = val
    },
    setUserPhoneNumber: (state, val) => {
      state.userPhoneNumber = val
    },
    setUserAccount: (state, val) => {
      state.userAccount = val
    },
    setUserToken: (state, val) => {
      state.userToken = val
    },
    setSKUUSArray: (state, val) => {
      state.skuus = val
    },
    setReceipts: (state, val) => {
      state.receipts = val
    },
    setPPHash: (state, val) => {
      let bitArray = sjcl.hash.sha256.hash(val)
      let hash = sjcl.codec.hex.fromBits(bitArray)
      state.ppHash = hash
    },
    setTermsHash: (state, val) => {
      let bitArray = sjcl.hash.sha256.hash(val)
      let hash = sjcl.codec.hex.fromBits(bitArray)
      state.termsHash = hash
    },
    setUUID: (state, val) => {
      state.UUID = val
    },
    setCountryCode: (state, val) => {
      state.countryCode = val
    },
    setActiveSubscriptions: (state, val) => {
      state.activeSubscriptions = val
    },
    setSearchTerm: (state, val) => {
      state.searchTerm = val
    },
    setRecordings: (state, val) => {
      state.recordings = val
    },
    setInvoices: (state, val) => {
      if(val === null) {
        val = []
      }
      state.invoices = val
    },
    setLists: (state, val) => {
      state.lists = val
      if(state.lists[0].name !== 'dashboard.sidebar.all_lists') {
        state.lists.unshift({ id: 'all_lists', name: 'dashboard.sidebar.all_lists' })
      }
    },
    setActiveTab: (state, val) => {
      state.activeTab = val
    },
    setMobileShowFilterLists: (state, val) => {
      state.mobileShowFilterLists = val
    },
    setShowQRCodes: (state, val) => {
      state.showQRCodes = val
    }
  },
  actions: {
    getIP({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get('https://api.ipify.org').then((response) => {
          if(response && response.data) dispatch('fetchCountryCode', response.data)
        }).catch((error)=> {
          commit('setCountryCode', 'US')
          reject(error)
        })
      })
    },
    fetchCountryCode({commit}, ipAddress) {
      return new Promise((resolve, reject) => {
        axios.get(IP_URL + ipAddress).then((response) => {
          let countryCode = response.data.split(";")[1]
          commit('setCountryCode', countryCode)
          resolve(200)
        }).catch((error)=> {
          commit('setCountryCode', 'US')
          reject(error)
        })
      })
    },
    getPP({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + PRIVACY_API_URL, config).then((response) => {
          commit('setPPHash', response.data)
          resolve(200)
        }).catch((error)=> {
          console.error('Error getting privacy policy', error)
          reject(error)
        })
      })
    },
    getTerms({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + TOS_API_URL, config).then((response) => {
          commit('setTermsHash', response.data)
          resolve(200)
        }).catch((error)=> {
          console.error('Error getting terms of service', error)
          reject(error)
        })
      })
    },
    getSKUUS({commit, dispatch}) {
      //reset skuus because we are manually pushing lto
      commit('setSKUUSArray', [])
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + SKU_API_URL, config).then((response) => {
          if(response.data && response.data.trec) {
            commit('setSKUUSArray', response.data.trec)
            dispatch('fetchSubscriptions')
            resolve(response.data.trec)
          } else {
            resolve(200)
          }
        }).catch((error)=> {
          console.error('Error getting skuus', error)
          reject(error)
        })
      })
    },
    loginSignupUser({commit, state, dispatch}, idToken) {
      return new Promise((resolve, reject) => {
        axios.put(BASE_URL + SIGNUP_API_URL, {
          'id_token': idToken,
          'pp_hash': state.ppHash,
          'tos_hash': state.termsHash
        }, config).then((response) => {
          localStorage.setItem(LAST_LOGIN, new Date().getTime())
          commit('setUserToken', response.data.token)
          dispatch('fetchUUID')
          resolve(response.data.new_user)
        }).catch((error)=> {
          console.error('Error ', error.response.status)
          reject(error.response.status)
        })
      })
    },
    submitPayment({state}, paymentData) {
      return new Promise((resolve, reject) => {
        axios.post(BASE_URL + SUBSCRIPTION_URL + '?token=' + state.userToken,{
          'nonce' : paymentData.nonce,
          'first_name' : paymentData.firstName,
          'last_name' : paymentData.lastName,
          'email_address' : paymentData.emailAddress,
          'sku_id' : paymentData.skuuID
        }, config).then((response) => {
          resolve(response)
        }).catch((error)=> {
          console.error('checkout error', error)
          reject(error)
        })
      })
    },
    fetchSharingUrl({state}, id) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + ADJUST_RECORDING + id + '/share' +  '?token=' + state.userToken).then((response) => {
          resolve(response)
        }).catch((error)=> {
          console.error('share error', error)
          reject(error)
        })
      })
    },
    fetchUUID({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + GET_UUID + '?token=' + state.userToken).then((response) => {
          if(response.data.id) {
            commit('setUUID', response.data.id)
          }
          resolve(response.data.id)
        }).catch((error)=> {
          console.error('UUID error', error)
          reject(error)
        })
      })
    },
    fetchSubscriptions({state, commit}) {
      if(!state.userToken) return
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + SUBSCRIPTION_URL + '?token=' + state.userToken).then((response) => {
          if(response.data.subscriptions) {
            commit('setActiveSubscriptions', response.data.subscriptions)
          }
          resolve(200)
        }).catch((error)=> {
          console.error('Get subscriptions error', error)
          reject(error)
        })
      })
    },
    cancelSubscription({state, dispatch}, subID) {
      return new Promise((resolve, reject) => {
        axios.delete(BASE_URL + SUBSCRIPTION_URL + subID + '?token=' + state.userToken).then((response) => {
          dispatch('fetchSubscriptions')
          resolve(response)
        }).catch((error)=> {
          console.error('Cancel subscriptions error', error.response.data)
          reject(error)
        })
      })
    },
    getReceipt({commit, dispatch, state}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + RECEIPT_API_URL + '?token=' + state.userToken, config).then((response) => {
          if(response.data) {
            commit('setReceipts', response.data)
            dispatch('fetchSubscriptions')
          }
          resolve(response)
        }).catch((error)=> {
          if(error && error.response) {
            reject(error.response.status)
          } else {
            reject(error)
          }
        })
      })
    },
    fetchAccount({state, commit}) {
      let URI = BASE_URL + FETCH_ACCOUNT + '?token=' + state.userToken
      if(state.startDate && state.endDate) {
        URI += '&startDate=' + state.startDate.getFullYear() + '-' + ("0" + (state.startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + state.startDate.getDate()).slice(-2) +
        '&endDate=' + state.endDate.getFullYear() + '-' + ("0" + (state.endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + state.endDate.getDate()).slice(-2)
      }

      return new Promise((resolve, reject) => {
        axios.get(URI, config).then((response) => {
         commit('setUserAccount', response.data)
        }).catch((error)=> {
          if(error && error.response) {
            reject(error.response.status)
          } else {
            reject(error)
          }
        })
      })
    },
    acceptTerms({state}) {
      return new Promise((resolve, reject) => {
        axios.put(BASE_URL + CONSENT + '?phone_number=' + state.userPhoneNumber,{
          'pp_hash' : state.ppHash,
          'tos_hash' : state.termsHash
        }, config).then((response) => {
         resolve(response)
        }).catch((error)=> {
          if(error && error.response) {
            reject(error.response.status)
          } else {
            reject(error)
          }
        })
      })
    },
    transcribeRecording({state, dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + ADJUST_RECORDING + id + '/transcription' + '?token=' + state.userToken, config).then((response) => {
          dispatch('fetchRecordings')
          resolve(response)
        }).catch((error)=> {
          reject(error)
        })
      })
    },
    uploadedRecording({state, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post(BASE_URL + UPLOADED_RECORDING + '?token=' + state.userToken,{
          'path' : data.path,
          'duration': data.duration
        }, config).then((response) => {
          dispatch('fetchRecordings')
          resolve(response)
        }).catch((error)=> {
          console.error('Uploaded recording error', error)
          reject(error)
        })
      })
    },
    editTranscription({state, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.put(BASE_URL + EDIT_TRANSCRIPTION + data.transcription_id + '?token=' + state.userToken, JSON.stringify(data.transcription), config).then((response) => {
          dispatch('fetchRecordings')
          resolve(response)
        }).catch((error)=> {
          console.error('Error ', error)
          reject(error)
        })
      })
    },
    fetchRecordings({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + FETCH_RECORDINGS + '?token=' + state.userToken + '&limit=false', config).then((response) => {
         commit('setRecordings', response.data.calls)
        }).catch((error)=> {
          if(error && error.response) {
            reject(error.response.status)
          } else {
            reject(error)
          }
        })
      })
    },
    deleteRecordingsApi({state}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(BASE_URL + ADJUST_RECORDING + id + '?token=' + state.userToken, config).then((response) => {
          resolve(response)
        }).catch((error)=> {
          reject(error.response.status)
        })
      })
    },
    fetchInvoices({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + BT_INVOICES + '?token=' + state.userToken, config).then((response) => {
         commit('setInvoices', response.data)
        }).catch((error)=> {
          if(error && error.response) {
            reject(error.response.status)
          } else {
            reject(error)
          }
        })
      })
    },
    addNewList({state, dispatch}, name) {
      return new Promise((resolve, reject) => {
        axios.post(BASE_URL + ADD_NEW_CATEGORY + '?token=' + state.userToken,{
          'name' : name
        }, config).then((response) => {
          dispatch('fetchLists')
          resolve(response)
        }).catch((error)=> {
          console.error('add New List error', error)
          reject(error)
        })
      })
    },
    updateLists({state, dispatch}, list) {
      return new Promise((resolve, reject) => {
        axios.put(BASE_URL + ADD_NEW_CATEGORY + '/' + list.id + '?token=' + state.userToken,{
          'name' : list.name
        }, config).then((response) => {
          dispatch('fetchLists')
          resolve(response)
        }).catch((error)=> {
          console.error('add New List error', error)
          reject(error)
        })
      })
    },
    deleteList({state, dispatch}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(BASE_URL + ADD_NEW_CATEGORY + '/' + id + '?token=' + state.userToken, config).then((response) => {
          dispatch('fetchLists')
          resolve(response)
        }).catch((error)=> {
          console.error('add New List error', error)
          reject(error)
        })
      })
    },
    addCategoriesToRecording({state, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.put(BASE_URL + ADJUST_RECORDING + data.recordingID + '/category/' + '?token=' + state.userToken,{
          'categories' : data.lists
        }, config).then((response) => {
          dispatch('fetchRecordings')
          resolve(response)
        }).catch((error)=> {
          console.error('add category to recording', error)
          reject(error)
        })
      })
    },
    fetchLists({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(BASE_URL + FETCH_CATEGORIES + '?token=' + state.userToken, config).then((response) => {
          if(response.data && response.data.categories && response.data.categories.length === 0) {
            dispatch('addNewList', 'Favorites')
          } 
          
          if(response.data && response.data.categories && response.data.categories.length > 0) {
            commit('setLists', response.data.categories)
          }
          resolve(response)
        }).catch((error)=> {
          reject(error.response.status)
        })
      })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit('setActiveSubscriptions', [])
        commit('setUserToken', null)
        commit('setReceipts', null)
        localStorage.removeItem(VUEX)
        localStorage.removeItem(LAST_LOGIN)
        resolve(200)
      })
    }
   },
})
